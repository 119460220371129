import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import email from '../assets/imgs/eicon.png';
import emailMobile from '../assets/imgs/mobile/eicon.png';
 function Footer(props) {

   const title1 = 'Contáctanos'
  const title2 = 'Normativas'
  const privacidad = '<Privacidad de ÁgilSol>'
  const terminos = '<Terminos de Uso>'
  const emailUrl = 'hola@agilsol.com'
  if(props.isMobile) {
    return (
      <footer id="footer" className="footer-mobile">
         <div className="one-center">
          <div className='title'>{title2}</div>
          
          <Router>
            <div className='infos' >
            <Link to="/privacidad_ags" target="_blank">
            {privacidad}
            </Link>
            </div>
            <div className='infos'>
            <Link to="/servicio_ags" target="_blank">{terminos}</Link>
            </div>
          </Router>
        </div>
        <div className="one-center-end">
             
            <div className='title'>{title1}</div>
            <div className='infos'>
              <img src={emailMobile} className="contImg" alt='fottOne'/>
              <div>{emailUrl}</div>
            </div>
          </div>
     
    </footer>
    )
  }
  return (
    <footer id="footer" className={'footer'}>
      <div className="bar">
      <div className="one-center one-center-end">
          <div className='title'>{title1}</div>
            <div className='infos'>
              <img src={email} className="contImg" alt='fottOne'/>
              <div>{emailUrl}</div>
            </div>
      </div>
      <div className="one-center">
      <div className='title'>{title2}</div>
            <Router>
              <div className='infos' >
              <Link to="/privacidad_ags" target="_blank">
               <div>{privacidad}</div>
              </Link>
              </div>
              <div className='infos'>
              <Link to="/servicio_ags" target="_blank">
                
                <div>{terminos}</div>
                </Link>
              </div>
            </Router>

           
            
          </div>
         
      </div>
      <div className="bottom-bar">
        Copyright © 2024 | ÁgilSol | All Rights Reserved
      </div>
    </footer>
  );
}


export default Footer;
