import React, { Component } from 'react';
import Primero from './Primero';
import privacyPolicyAgs from './Page/privacidad_ags';
import privacyPolicy from './Page/privacidad';
import serverPolicy from './Page/servicio_ags';
import eliminarDatos from './Page/eliminarDatos';
import ErrorInfo from './Page/ErrorInfo';
import { Router, Route } from "react-router-dom";
import {createBrowserHistory} from 'history'
const browserHistory = createBrowserHistory()
class App extends Component {
  render() {
    console.log('----App----')
    return (
      <Router history={browserHistory}>
        <Route exact path="/" component={Primero} />
        <Route exact path="/privacidad_ags" component={privacyPolicyAgs} />
        <Route exact path="/privacidad" component={privacyPolicy} />
        <Route exact path="/servicio_ags" component={serverPolicy} />
        <Route exact path="/errorInfo" component={ErrorInfo} />
        <Route exact path="/eliminarDatos" component={eliminarDatos} />
      </Router>
    );
  }
}

export default App;
